import * as Yup from 'yup';

const VirtualTerminalValidationSchema = Yup.object().shape({
  orderId: Yup.string()
    .trim('Enter valid Order ID')
    .strict(true)
    .required('Order ID is required'),
  sendEmail: Yup.boolean().required('Send email confirmation is required'),
  email: Yup.string().when(
    'sendEmail',
    {
      is: true,
      then: Yup.string().email().required('Email is required')
    }
  )
});

export default VirtualTerminalValidationSchema;
