import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import cancelSubscriptionMutation from '../../mutations/cancelSubscription';
import getCancellationReasonsQuery from '../../../config/queries/cancellation-reasons';

import CircleProgress from '../../../../components/progress/circle';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  formField: {
    width: '90%',
    marginTop: '10px'
  },
  actions: {
    paddingBottom: '20px'
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: '10px'
  }
}));

const CancelDialog = (props) => {
  const classes = useStyles();
  const {
    handleClose,
    dialogOpen,
    customerId,
    history,
    subscriptionId
  } = props;
  const initialFormValues = {
    reason: '',
    notes: ''
  };
  const [cancelSubscription, { loading, error }] = useMutation(
    cancelSubscriptionMutation,
    {
      client: props.client,
      onCompleted: () => history.push(`/customer/${customerId}`)
    }
  );
  const { data, loading: reasonsLoading } = useQuery(
    getCancellationReasonsQuery,
    { client: props.client }
  );
  const handleMutation = async (values) => {
    cancelSubscription({
      variables: {
        input: {
          subscriptionId,
          cancellationNote: values.notes || null,
          reasonCode: values.reason || null
        }
      }
    });
  };

  const renderMenu = (reasons) => {
    if (!reasons || !Array.isArray(reasons) || reasons.length < 0) {
      return <MenuItem key={'none'} value={'none'}>none</MenuItem>;
    }
    return (
      reasons.map(
        (reason) => <MenuItem key={reason.code} value={reason.code}>{reason.name}</MenuItem>
      )
    );
  };

  return (
    <Dialog open={dialogOpen}>
      <DialogContent>
        <DialogTitle disableTypography>
          <Typography variant="h3">Confirm Cancel Subscription</Typography>
          {error ? (
            <p className={classes.errorText}>There was an error</p>
          ) : null}
        </DialogTitle>
        <Formik
          initialValues={initialFormValues}
          onSubmit={handleMutation}
          render={({
            values,
            errors,
            status,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting
          }) => {
            if (reasonsLoading) {
              return <CircleProgress size="1em" />;
            }
            if (
              data
              && data.getMerchantConfig
              && Array.isArray(data.getMerchantConfig)
              && data.getMerchantConfig.length > 0
            ) {
              const { getMerchantConfig } = data;
              const config = getMerchantConfig[0];
              const { cancellationReasons } = config;
              const menu = renderMenu(cancellationReasons);
              return (
                <form onSubmit={handleSubmit} id="cancel-form">
                  <div className={classes.formContainer}>
                    <TextField
                      select
                      size="small"
                      variant="outlined"
                      name="reason"
                      className={classes.formField}
                      value={values.reason}
                      onChange={handleChange}
                      label="Reason"
                    >
                      {menu}
                    </TextField>
                    <TextField
                      size="small"
                      className={classes.formField}
                      variant="outlined"
                      name="notes"
                      rows={5}
                      multiline
                      label="Notes"
                      value={values.notes}
                      onChange={handleChange}
                    />
                  </div>
                </form>
              );
            }

            return 'nothing';
          }}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          form="cancel-form"
          variant="contained"
          color="primary"
          type="submit"
          disabled={!!loading}
        >
          {loading ? 'Processing' : 'Submit'}
        </Button>
        <Button
          disabled={!!loading}
          variant="outlined"
          color="primary"
          onClick={handleClose}
        >
          Go Back
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelDialog;
