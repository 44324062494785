import React, { Fragment, useState, useEffect } from 'react';
import * as dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MUIDataTable from 'mui-datatables';
import searchCustomers from './queries/searchCustomers';
import EmptyState from '../../components/empty-state';
import CircleProgress from '../../components/progress/circle';
import { getQueryStringParameter } from '../utils/queryString';

const useStyles = makeStyles({
  searchBar: {
    marginTop: '25px',
    marginBottom: '25px',
    display: 'flex !important',
  },
});

const options = {
  elevation: 0,
  selectableRows: 'none',
  download: false,
  print: false,
  viewColumns: false,
  search: false,
  filter: false,
  rowsPerPage: [50],
  rowsPerPageOptions: false,
  sortOrder: { name: 'Created At', direction: 'asc', default: true }
};

const columns = [
  {
    name: '',
    options: { filter: false, sort: false },
  },
  {
    name: 'Customer ID',
    options: { filter: false, sort: true },
  },
  {
    name: 'Last Name',
    options: { filter: false, sort: true },
  },
  {
    name: 'First Name',
    options: { filter: false, sort: true },
  },
  {
    name: 'Email',
    options: { filter: false, sort: true },
  },
  {
    name: 'Address',
    options: { filter: false, sort: true },
  },
  {
    name: 'Created At',
    options: { filter: false, sort: true },
  },
  {
    name: 'Updated On',
    options: { filter: false, sort: true },
  },
  // {
  //   name: 'Updated By',
  //   options: { filter: false, sort: true },
  // },
];

const renderViewButton = (id, navToView) => (
  <Button
    color="primary"
    size="small"
    onClick={() => {
      navToView(id);
    }}
  >
    View
  </Button>
);

const CustomerList = (props) => {
  const classes = useStyles();
  const { history } = props;
  const [searchInput, setSearchInput] = useState(getQueryStringParameter('search'));
  const { loading, error, data } = useQuery(searchCustomers, {
    fetchPolicy: 'no-cache',
    skip: searchInput.length < 0,
    variables: {
      input: {
        customerSearchTerm: `${searchInput}`,
      },
    },
  });

  const navigateToView = (customerId) => {
    history.push(`/customers?search=${searchInput}`);
    history.push(`/customer/${customerId}`);
  };

  const handleChange = (event) => {
    const { target } = event;
    const { value } = target;
    setSearchInput(value);
  };

  const getCustomerRow = (customer) => {
    const {
      id, lastName, firstName, email, externalId, address, created, modified, modifiedBy,
    } = customer;
    const button = <div>{renderViewButton(id, navigateToView)}</div>;
    return [button, externalId, lastName, firstName, email, address,
      dayjs(created).format('MM/DD/YYYY'), dayjs(modified).format('MM/DD/YYYY'), modifiedBy];
  };

  useEffect(() => {
    setSearchInput(getQueryStringParameter('search'));
  }, []);

  const renderTableWithData = (tableData) => {
    const { customers } = tableData;
    if (!customers || customers.length === 0) {
      return <EmptyState message="no customers to show" />;
    }
    const rows = customers.map((customer) => getCustomerRow(customer));
    // Re order customers by modified date - date format is 2023-09-20T20:06:23.586Z
    rows.sort((a, b) => {
      const aDate = new Date(a.modified);
      const bDate = new Date(b.modified);
      return bDate - aDate;
    });
    console.log(customers);
    return <MUIDataTable data={rows} columns={columns} options={options} />;
  };

  return (
    <Fragment>
      <TextField
        defaultValue={searchInput}
        placeholder="Customer Search"
        onChange={handleChange}
        className={classes.searchBar}
        InputProps={{
          startAdornment: (
            <InputAdornment className={classes.input} position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {loading && <CircleProgress size="1em" />}
      {error && <p>{error.message} :(</p>}
      {data && renderTableWithData(data)}
    </Fragment>
  );
};

export default CustomerList;
