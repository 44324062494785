import React, { useContext } from 'react';
import axios from 'axios';
import TreeView from 'devextreme-react/tree-view';

import 'devextreme/dist/css/dx.light.css';

import Can from '../../components/auth/userCanPerform';
import { authContext } from '../../contexts/authContext';
import constants from '../../constants';

const {
  BASE_URL,
  REPORTS_API_KEY,
  AWS_BUCKET
} = constants.reports;

const getNodeName = (name, isFolder) => {
  const pathStrings = name.split('/');
  return pathStrings[pathStrings.length - (isFolder ? 2 : 1)];
};

const createChildren = async (parent) => {
  try {
    const parentId = parent ? parent.itemData.id : '';

    const { data } = await axios.post(BASE_URL, {
      Bucket: AWS_BUCKET,
      Delimiter: '/',
      Prefix: parentId
    }, {
      headers: { 'x-api-key': REPORTS_API_KEY }
    });

    const folders = data.CommonPrefixes.map((res) => ({
      id: res.Prefix,
      text: getNodeName(res.Prefix, true),
      parentId,
      hasItems: true,
      icon: 'inactivefolder'
    }));
    const files = data.data.filter((res) => res.Size > 0).map((res) => ({
      id: res.Key,
      text: getNodeName(res.Key, false),
      parentId,
      hasItems: false,
      icon: 'file'
    }));

    return [...folders, ...files];
  } catch (err) {
    console.log(err);
    return [];
  }
};

const Reports = () => {
  const auth = useContext(authContext);

  const handleItemClick = async ({ itemData }) => {
    if (itemData.hasItems) {
      return;
    }

    const url = `${BASE_URL}/${itemData.id}`;
    const response = await axios.get(url, {
      headers: { 'x-api-key': REPORTS_API_KEY },
      responseType: 'blob'
    });

    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    const fileName = getNodeName(itemData.id, false);
    fileLink.setAttribute('download', fileName);
    fileLink.setAttribute('target', '_blank');
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove();
  };

  return (
    <Can
      groups={auth.groups}
      perform="reports:access"
      yes={() => (
        <TreeView
          id="simple-treeview"
          dataStructure="plain"
          rootValue=""
          expandNodesRecursive={false}
          createChildren={createChildren}
          onItemClick={handleItemClick}
        />
      )}
    />
  );
};

export default Reports;
