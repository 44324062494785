import _ from 'lodash';
import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import Typography from '@material-ui/core/Typography';
import Constants from '../../../constants';

import {
  getVaultToken,
  finalizeWithVault,
  tokenizeDDAWithVault
} from '../../utils/payment-method';

import {
  getDDAPaymentMethod,
  getPaymentMethodInput
} from '../../utils/get-payment-method';

import getCustomerQuery from '../queries/getCustomer';
import getPaymentMethodsQuery from '../queries/getPaymentMethods';

import storeDDAPaymentMethodMutation from '../mutations/storeDDAPaymentMethod';
import storePaymentMethodMutation from '../mutations/storePaymentMethod';

import AddButton from '../../../components/buttons/add-button';
import PaymentMethodForm from '../payment-method/add-payment-method';

import '../payment-method/payment-method.scss';

const { paymentMethod } = Constants;
const { successCodes } = paymentMethod;

const PaymentMethod = (props) => {
  const { customerId, client } = props;
  const [token, setToken] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showAddButton, setShowAddButton] = useState(true);
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [paymentOption, setPaymentOption] = useState('ach');
  const [accountType, setAccountType] = useState('checking');

  const { loading: customerLoading, data: customerData } = useQuery(
    getCustomerQuery,
    {
      client,
      variables: { customerId }
    }
  );
  const { loading: paymentMethodLoading, data: paymentMethodData } = useQuery(
    getPaymentMethodsQuery,
    {
      client,
      variables: { input: { externalId: customerId } }
    }
  );
  const [DDAPaymentMethod] = useMutation(storeDDAPaymentMethodMutation, {
    refetchQueries: [
      {
        query: getPaymentMethodsQuery,
        variables: { input: { externalId: customerId } }
      }
    ]
  });
  const [PaymentMethodMutation] = useMutation(storePaymentMethodMutation, {
    refetchQueries: [
      {
        query: getPaymentMethodsQuery,
        variables: { input: { externalId: customerId } }
      }
    ]
  });

  if (customerLoading || paymentMethodLoading) {
    return null;
  }

  const { getCustomer: customer } = customerData;
  const { getPaymentMethods } = paymentMethodData;

  const existingPaymentMethods = getPaymentMethods && Array.isArray(getPaymentMethods)
    ? getPaymentMethods
    : [];
  const initialFormValues = {
    accountHolderFirstName: customer.firstName,
    accountHolderLastName: customer.lastName,
    billingAddress: '',
    billingAddress2: '',
    billingLocality: '',
    billingRegion: '',
    billingPostal: '',
  };

  const resetState = () => {
    setShowForm(false);
    setShowAddButton(true);
    setIsSaving(false);
    setToken(null);
  };

  const getToken = async () => {
    setShowForm(true);
    setShowAddButton(false);
    setError(null);

    try {
      const results = await getVaultToken();

      if (results && results.success === false) {
        const vaultError = _.get(results, 'error');
        resetState();
        setError(vaultError);

        return null;
      }

      const { token: newToken } = results;
      setToken(newToken);
      return results;
    } catch (err) {
      resetState();
      const errorMessage = err && err.message ? err.message : 'There was an error';
      setError(errorMessage);

      return null;
    }
  };

  const handleFinalize = async (values) => {
    if (!token) {
      return [];
    }
    setError(null);
    try {
      const result = await finalizeWithVault({ token });

      if (result && result.success === false) {
        // failure here could be vault technical errors or auth failures.
        resetState();
        setError('Add payment method failed.');

        return null;
      }
      /*
        dont allow user to add the same credit card.
      */
      const existingMethod = existingPaymentMethods.findIndex(
        (item) => item.token === result.token
      );

      if (existingMethod !== -1) {
        resetState();
        setError('That card already exists. Please enter a different card.');

        return null;
      }

      return result;
    } catch (err) {
      resetState();
      const errorMessage = err && err.message ? err.message : 'there was an error';
      setError(errorMessage);

      return null;
    }
  };

  const handleTokenizeDDAPaymentMethod = async (values) => {
    if (!token) {
      return [];
    }
    const params = {
      account: values.accountNumber,
      routing: values.routingTransitNumber,
      type: accountType,
      token
    };
    const result = await tokenizeDDAWithVault(params);

    if (result && result.success === false) {
      const vaultError = _.get(result, 'error');
      // failure here could be vault technical errors
      setError(vaultError);
      return result;
    }

    return result;
  };

  const storePaymentMethod = async (args) => {
    try {
      const results = await PaymentMethodMutation({ variables: { input: args } });
      const { data } = results;
      const { storePaymentMethod: paymentMethodResult } = data;
      const validationResult = _.get(paymentMethodResult, '0', {});

      if (validationResult && !successCodes.includes(validationResult.code)) {
        const { code, status } = validationResult;

        return {
          code: validationResult.code,
          error: `Card not authorized. Status code ${code}. ${status}`,
          success: false
        };
      }

      return {
        ...args,
        ...validationResult,
        success: true
      };
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err.message || 'storePaymentMethod::something went wrong');
      return {
        success: false
      };
    }
  };

  const handleMutation = async (values) => {
    setIsSaving(true);
    try {
      if (paymentOption === 'ach') {
        const finalizeResult = await handleTokenizeDDAPaymentMethod(values);

        if (!finalizeResult || (finalizeResult && finalizeResult.success === false)) {
          setIsSaving(false);

          return null;
        }

        const ddaPaymentMethod = getDDAPaymentMethod({
          ...values,
          ...finalizeResult,
          customerId
        });
        const storeDDAPaymentMethod = async (args) => {
          try {
            const results = await DDAPaymentMethod({ variables: { input: args } });
            const { data } = results;
            const { storeDDAPaymentMethod: response } = data;
            const ddaPaymentMethodObject = _.get(response, '0', {});

            if (!ddaPaymentMethodObject) {
              return {
                error: 'DDA account not stored.',
                success: false
              };
            }
            return {
              ...args,
              ...ddaPaymentMethodObject,
              success: true
            };
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err.message || 'storeDDAPaymentMethod::something went wrong');
            return {
              success: false
            };
          }
        };
        const results = await storeDDAPaymentMethod(ddaPaymentMethod);

        if (results && results.success === false) {
          const storageError = _.get(results, 'error');
          // failure here would be graphql issue
          setError(storageError || 'Add payment method failed.');

          return null;
        }
        resetState();

        return null;
      }
      const finalizedPaymentMethod = await handleFinalize(values);
      const paymentMethodToStore = getPaymentMethodInput({
        ...values,
        ...finalizedPaymentMethod
      });
      // this runs the validation
      const results = await storePaymentMethod(
        {
          ...paymentMethodToStore,
          customerId
        }
      );
      if (results && results.success === false) {
        const storageError = _.get(results, 'error');
        // failure here would be graphql issue
        setError(storageError || 'Add payment method failed.');

        return null;
      }

      resetState();

      return null;
    } catch (err) {
      resetState();
      const errorMessage = err.message ? err.message : 'there was an error';
      if (!errorMessage) setError(errorMessage);

      return null;
    }
  };

  return (
    <div className="payment-methods">
      <div className="payment-methods-header">
        <Typography variant="h3">Payment Methods</Typography>
        {showAddButton ? <AddButton onClick={getToken} label={'Add'} /> : null}
      </div>
      {error ? (
        <Typography variant="body1" color="error" gutterBottom={true}>
          {error}
        </Typography>
      ) : null}
      {showForm ? (
        <Formik className="payment-method-row"
          initialValues={initialFormValues}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleMutation}
          render={({
            values,
            errors,
            status,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting
          }) => (
              <form onSubmit={handleSubmit}>
                <PaymentMethodForm
                  customerId={props.customerId}
                  token={token}
                  error={error}
                  resetState={resetState}
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleSubmit={handleSubmit}
                  disabled={isSaving}
                  paymentOption={paymentOption}
                  setPaymentOption={setPaymentOption}
                  accountType={accountType}
                  setAccountType={setAccountType}
                  showManual={false}
                />
              </form>
          )}
        />
      ) : null}
    </div>
  );
};

export default PaymentMethod;
