import { gql } from '@apollo/client';

export default gql`
  query getInvoicesQuery($input: GetByCustomerIdInput!) {
    getInvoiceByCustomerId(input: $input) {
      id
      number
      subscriptionId
      balance
      created
      modified
      status
      total
      totalTax
      externalId
      period {
        start
        end
      }
      lineItems {
        currency
      }
      paymentMethodIds
    }
  }
`;
