import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import PeopleIcon from '@material-ui/icons/People';
import FolderIcon from '@material-ui/icons/Folder';
import ReportIcon from '@material-ui/icons/Report';
import ReceiptIcon from '@material-ui/icons/Receipt';
// import Fade from '@material-ui/core/Fade';
import LinkIcon from '@material-ui/icons/Link';
// import SettingsIcon from '@material-ui/icons/Settings';
// import GraphicEqIcon from '@material-ui/icons/GraphicEq';

import isAuthenticated from '../auth/isAuthenticated';
import Can from '../auth/userCanPerform';
import ExternalLink from './external-link';

import './side-navigation.scss';
import SVGIcon from '../../icons';

const drawerWidth = 'auto';
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    order: 0,
    position: 'relative',
    height: '100vh',
    marginRight: '20px'
  },
  drawerPaper: {
    background: '#565656',
    color: '#FFF',
    width: drawerWidth,
    position: 'unset'
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3)
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: '55px'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px'
  },
  icon: {
    minWidth: 0,
    marginRight: '15px'
  },
  noIcon: {
    paddingLeft: '55px'
  },
  normalCursor: {
    cursor: 'default'
  },
  appMenu: {
    marginBottom: '15px'
  },
  divider: {
    borderTop: '1px solid white'
  },
  externalLink: {
    color: '#FFF',
    cursor: 'pointer',
    fontSize: '1em',
    '&:hover': {
      color: '#bababa'
    }
  }
}));

const SideNavigation = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const { auth } = props;
  const _drawerClass = open ? classes.drawerOpen : classes.drawerClose;
  const activeLinkColor = 'active-link-color';
  const isSmallScreen = useMediaQuery('(max-width:500px)');

  function handleDrawerToggle() {
    setOpen(!open);
  }

  useEffect(() => {
    if (isSmallScreen) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isSmallScreen]);

  return (
    <Drawer
      className={`${classes.drawer} ${_drawerClass}`}
      variant="permanent"
      anchor="left"
      classes={{
        paper: `${classes.drawerPaper} ${_drawerClass}`
      }}
      open={open}
    >
      <List className={classes.appMenu} data-cy="side-navigation">
        <ListItem onClick={handleDrawerToggle} button key="menu">
          <ListItemIcon>
            <SVGIcon name="menu" width="24px" height="24px" />
          </ListItemIcon>
        </ListItem>
        <NavLink to="/" activeClassName={activeLinkColor}>
          <ListItem button key="dashboard">
            <ListItemIcon className={classes.icon}>
              <SVGIcon name="dial" width="27.42px" height="24px" />
            </ListItemIcon>
            <ListItemText className="list-item-link" primary="Home" />
          </ListItem>
        </NavLink>
        <Can
          groups={auth.groups}
          perform="customers:view"
          yes={() => (
            <NavLink to="/customers" activeClassName={activeLinkColor}>
              <ListItem button key="customers">
                <ListItemIcon className={classes.icon}>
                  <PeopleIcon htmlColor="white" />
                </ListItemIcon>
                <ListItemText className="list-item-link" primary="Customers" />
              </ListItem>
            </NavLink>
          )}
        />
        <Can
          groups={auth.groups}
          perform="products:view"
          yes={() => (
            <NavLink to="/products" activeClassName={activeLinkColor}>
              <ListItem button key="products">
                <ListItemIcon className={classes.icon}>
                  <FolderIcon htmlColor="white" />
                </ListItemIcon>
                <ListItemText className="list-item-link" primary="Products" />
              </ListItem>
            </NavLink>
          )}
        />
        <Can
          groups={auth.groups}
          perform="reports:access"
          yes={() => (
            <NavLink to="/reports" activeClassName={activeLinkColor}>
              <ListItem button key="reports">
                <ListItemIcon className={classes.icon}>
                  <ReportIcon htmlColor="white" />
                </ListItemIcon>
                <ListItemText className="list-item-link" primary="Reports" />
              </ListItem>
            </NavLink>
          )}
        />
         <Can
          groups={auth.groups}
          perform="invoices:view"
          yes={() => (
            <NavLink to="/invoices" activeClassName={activeLinkColor}>
              <ListItem button key="invoices">
                <ListItemIcon className={classes.icon}>
                  <ReceiptIcon htmlColor="white" />
                </ListItemIcon>
                <ListItemText className="list-item-link" primary="Open Invoices" />
              </ListItem>
            </NavLink>
          )}
        />
        {/* <NavLink to="/batch-processing" activeClassName={activeLinkColor}>
          <ListItem button key="batchProcessing">
            <ListItemIcon className={classes.icon}>
              <GraphicEqIcon htmlColor="white" />
            </ListItemIcon>
            <ListItemText
              className="list-item-link"
              primary="Batch Processing"
            />
          </ListItem>
        </NavLink>
        <Can
          groups={auth.groups}
          perform="config:view"
          yes={() => {
            return (
              <NavLink to="/config" activeClassName={activeLinkColor}>
                <ListItem button key="configuration">
                  <ListItemIcon className={classes.icon}>
                    <SettingsIcon htmlColor="white" />
                  </ListItemIcon>
                  <ListItemText
                    className="list-item-link"
                    primary="Configuration"
                  />
                </ListItem>
              </NavLink>
            );
          }}
        /> */}
      </List>
      <Divider variant="middle" className={classes.divider} />
      <ListItem button key="links">
        <ListItemIcon className={classes.icon}>
          <LinkIcon htmlColor="white" />
        </ListItemIcon>
        <ExternalLink
          source="https://rebartechnology.atlassian.net/servicedesk/customer/portal/1/group/-1"
          text="Support"
        />
      </ListItem>
      {/* <List>
        <ListItem key="links" className={classes.normalCursor}>
          <ListItemIcon className={classes.icon}>
            <LinkIcon htmlColor="white" />
          </ListItemIcon>
          <ListItemText primary="Links" />
        </ListItem>
        <Fade in={open}>
          <div>
            <ExternalLink
              source="https://www.helpscout.com/helpu/knowledge-base-examples/"
              text="Knowledgebase"
            />
          </div>
        </Fade>
      </List> */}
    </Drawer>
  );
};

export default isAuthenticated(SideNavigation);
