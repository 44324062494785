import React from 'react';
import { Route, Switch } from 'react-router-dom';

import DashboardPage from './page';
import ProductsList from '../products/list';
import ProductAdd from '../product/add';
import ProductEdit from '../product/edit';
import ProductView from '../product/view';
import CustomerList from '../customers/list';
import CustomerView from '../customer/view/index';
import CustomerEdit from '../customer/edit/index';
import CustomerAdd from '../customer/add/index';
import VirtualTerminal from '../customer/virtual-terminal/index';
import BatchProcessing from '../batch-processing';
import SubscriptionEdit from '../customer/subscription/edit-subscription/index';
import Reports from '../reports';
import Invoices from '../invoices/list';
import AccountSettings from '../account-settings/page';

import ConfigRoutes from '../config/routes';

import isAuthenticated from '../../components/auth/isAuthenticated';
import SideNavigation from '../../components/side-navigation';
import NotFound from '../../components/not-found';

import './dashboard.scss';

const DashboardContainer = (dashboardProps) => (
  <div className="dashboard">
    <SideNavigation />
    <div className="content">
      <Switch>
        <Route exact path="/" component={DashboardPage} />
        <Route exact path="/product/add" component={ProductAdd} />
        <Route
          path="/products/"
          render={(props) => <ProductsList {...props} />}
        />
        <Route
          exact
          path="/product/:productId/edit"
          component={ProductEdit}
          render={(props) => <ProductEdit {...props} />}
        />
        <Route
          exact
          path="/product/:productId"
          render={(props) => <ProductView {...props} />}
        />
        <Route
          exact
          path="/customers"
          render={(props) => <CustomerList {...props} />}
        />
        <Route exact path="/customer/add" component={CustomerAdd} />
        <Route
          exact
          path="/customer/:customerId/edit"
          render={(props) => <CustomerEdit {...props} />}
        />
        <Route
          exact
          path="/customer/:customerId"
          render={(props) => <CustomerView {...props} />}
        />
        <Route
          exact
          path="/:customerId/subscription/:subscriptionId/edit"
          render={(props) => <SubscriptionEdit {...props} />}
        />
        <Route
          exact
          path="/batch-processing"
          render={(props) => <BatchProcessing {...props} />}
        />
        <Route
          path="/config"
          render={(props) => <ConfigRoutes {...props} />}
        />
        <Route
          exact
          path="/virtual-terminal/:customerId"
          render={(props) => <VirtualTerminal {...props} />}
        />
        <Route
          exact
          path="/reports"
          render={(props) => <Reports {...props} />}
        />
        <Route
          exact
          path="/invoices"
          render={(props) => <Invoices {...props} />}
        />
        <Route
          exact
          path="/account-settings"
          render={(props) => <AccountSettings {...props} />}
        />
        <Route component={NotFound} />
      </Switch>
    </div>
  </div>
);

export default isAuthenticated(DashboardContainer);
